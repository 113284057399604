
  import StepComponent from '~/components/Step'

  export default {
    name: `ExternalPage`,
    components: { StepComponent },
    options: {
      auth: false
    },
    data() {
      return {
        screener: null,
        lang: null,
        steps: null,
        headerMessage: null,
        numberStep: 0,
        currentStep: 1,
        formSent: false,
        message: undefined,
        status: null
      }
    },
    async fetch() {
      try {
        const {
          _data: { screener, lang, steps, header_message: headerMessage, success_message: successMessage }
        } = await this.$store.dispatch(`external/getExternal`)
        this.$store.commit(`external/setScreener`, screener)
        this.lang = lang
        if (this.storedData?.steps && this.storedData?.lang === this.lang) {
          this.steps = !!steps && this.getOrderedStep([
            ...steps.filter(s => s.readonly),
            ...this.storedData.steps.filter(s => !s.readonly)])
        } else {
          this.steps = !!steps && this.getOrderedStep(steps)
        }
        this.numberStep = this.steps?.length > 0 ? this.steps?.length : 0
        this.headerMessage = headerMessage
        this.message = successMessage
        this.status = `success`
      } catch (err) {
        console.log(err)
        this.status = `error`
        if (err?.response?.status === 401) {
          this.message = err.data.detail
        } else {
          throw new Error(err)
        }
      }
    },
    computed: {
      storedData: {
        get() {
          return this.$store.getters[`external/getData`](this.token)
        },
        set(srcData, oldData) {
          const srcDataWithoutNewDocuments = this.$helpers.removeNewDocumentFromScreening(srcData)
          const srcDataAndLangWithoutNewDocuments = { lang: this.lang, ...srcDataWithoutNewDocuments }
          if (JSON.stringify(oldData) !== JSON.stringify(srcDataAndLangWithoutNewDocuments)) {
            this.$store.commit(`external/setData`, { data: srcDataAndLangWithoutNewDocuments, token: this.token })
          }
        }
      },
      token() {
        return this.$router.history.current.query.token || this.$store.state.external.token
      },
      screenerPhone() {
        return this.$helpers.formatPhoneNumber(this.$store.state.external.screener?.phone)
      }
    },
    watch: {
      lang(value) {
        this.$i18n.setLocale(value)
      },
      async currentStep(value) {
        if (value > this.numberStep) {
          return null
        }
        const idStep = this.steps[value - 1].id
        const refStep = this.$refs[`step_${idStep}`][0]
        const inputs = refStep?.$el?.getElementsByTagName(`input`)
        await this.$helpers.focus({
          element: inputs
        })
        if (!!inputs[0]) {
          inputs[0].scrollIntoView({ behavior: `smooth`, block: `center` })
        }
      }
    },
    created() {
      if (!this.$router.history.current.query.token) {
        return null
      }
      this.$store.commit(`external/setToken`, this.$router.history.current.query.token)
    },
    mounted() {},
    updated() {},
    methods: {
      getOrderedStep(steps){
        return this.$helpers
          .sortElements(steps, (a, b) => a[1].order - b[1].order)
          .map((step) => ({
            ...step,
            currentAnswer: -1,
            valid: true,
            isComplete: step.readonly
          }))
      },
      async nextIfValid(id) {
        const index = this.steps.findIndex((step) => step.id === id)
        const refStep = this.$refs[`step_${id}`][0]
        const valid = await this.validateOneForm(refStep, index)

        !!index && (this.steps[index].valid = valid)
        !!valid ? this.nextStep() : this.goToFirstError(refStep)
      },
      async validateOneForm(ref, index) {
        if (!!this?.steps[index]?.readonly) {
          return true
        }
        return await ref.validate()
      },
      async validateAllForm() {
        const promise = this.steps.map((step, index) => this.validateOneForm(this.$refs[`step_${step.id}`][0], index))
        const isValid = await Promise.all(promise)
        return isValid.findIndex((value) => !value)
      },
      nextStep() {
        this.currentStep += 1
      },
      backStep() {
        this.currentStep += -1
      },
      gotoStep(index) {
        this.currentStep = index
      },
      async finalize() {
        const IS_VALID = -1
        const isValid = await this.validateAllForm()
        isValid !== IS_VALID && (this.steps[isValid].valid = false)
        isValid === IS_VALID ? this.sendForm() : this.goToStepError(isValid + 1)
      },
      async goToStepError(indexStep) {
        try {
          await this.gotoStep(indexStep)
          const idStep = this.steps[indexStep].id
          const refStep = this.$refs[`step_${idStep}`][0]
          this.goToFirstError(refStep)
        } catch (e) {
          console.error(e)
        }
      },
      goToFirstError(refStep) {
        this.$nextTick(() => {
          const el = refStep.$el.querySelector(`.v-messages.error--text`)
          el?.scrollIntoView({ behavior: `smooth`, block: `center` })
        })
      },
      async sendForm() {
        try {
          const res = await this.$store.dispatch(`external/putExternal`, this.filterformWithoutReadonly())
          this.succesSendForm(res)
        } catch (e) {
          this.errorSendForm(e)
        }
      },
      findFirstStepError(stepsError) {
        return this.steps.findIndex((step) => stepsError.find(({ step_errors: stepError }) => stepError.id === step.id))
      },
      async setErrors(step) {
        const id = step?.step_errors?.id
        const ref = this.$refs[`step_${id}`]
        if (!!ref) {
          const renderedStep = Array.isArray(ref) ? ref[0] : ref
          await renderedStep.setErrors(step.step_errors)
        }
      },
      filterformWithoutReadonly() {
        return this.steps.filter((step) => !step.readonly)
      },
      succesSendForm({ _data: data, ...res }) {
        this.$store.commit(`external/removeData`, this.token)
        this.$store.commit(`external/setToken`, null)
        this.headerMessage = data?.header_message
        this.formSent = true
        this.status = `success`
        this.message = this.$t(`pages.external.thanks_details`)
      },
      async errorSendForm({ response: { _data: data, status, ...response }, ...e }) {
        if (status === 400 && Array.isArray(data)) {
          await data.forEach(this.setErrors)
          const indexStepError = this.findFirstStepError(data)
          this.steps[indexStepError].valid = false
          this.goToStepError(indexStepError + 1)
        } else if (!!data.success_message) {
          this.status = `success`
          this.message = data.success_message
        } else {
          this.errorMessage(this.$t(`messages.cant_save_answer`))
        }
      },
      errorMessage(message) {
        this.$store.dispatch(`flashMessages/messageError`, { message })
      },
      updateStep() {
        this.storedData = { steps: this.steps }
      }
    }
  }
